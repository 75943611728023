<div class="modal-dialog cms-modal-dialog--locales">
    <div class="modal-content">
        <div class="cms-modal-header --importUsers">
            <button type="button" class="cms-button-close" data-dismiss="modal" aria-label="Close"><span
                    aria-hidden="true">&times;</span></button>
            <h3 class="cms-modal-title">User import</h3>
        </div>
        <div class="modal-body cms-modal-scrollbar">
            <div class="input-group" style="cursor: pointer;">
                <span class="input-group-addon" id="basic-addon1"><span class="glyphicon glyphicon-search"
                        aria-hidden="true"></span></span>
                <input type="text" class="form-control typeahead searchUsers" id="input-users-search"
                    placeholder="Type to search..." autocomplete="off" />
                <span class="input-group-addon viewDiff" id="basic-addon2">
                    <span class="glyphicon glyphicon-eye-{{#if viewDiff}}close{{else}}open{{/if}}"
                        aria-hidden="true"></span>
                    <span>{{#if viewDiff}}Hide{{else}}View{{/if}} Diff</span>
                </span>
            </div>
            <div>
                <h3>To Import</h3>
                <ul id="template-import-users-container" class="cms-users-list">
                    {{#each importUsers}}
                    <li data-content="{{ hepta }}" data-email="{{ email }}" id="modal-{{ hepta }}"
                        class="cms-user-item">
                        <div class="cms-user-cell form-checkbox">
                            <input type="checkbox" id="check-{{ hepta }}" class="cms-editable checkbox-user"
                                value='{ "hepta": "{{ hepta }}", "lastname": "{{ lastname }}", "firstname": "{{ firstname }}", "email": "{{ email }}", "profile": "{{ profile }}" }' />
                        </div>
                        <div class="cms-user-cell cms-user-cell--user cms-users-user">
                            <i class="fa fa-user-o" aria-hidden="true"></i>
                            <span class="cms-userCell-text cms-userCell-text--user">{{ hepta }}</span>
                        </div>
                        <div class="cms-user-cell cms-user-cell--email cms-users-email">
                            <i class="fa fa-envelope-o" aria-hidden="true"></i>
                            <span class="cms-userCell-text cms-userCell-text--email">{{ email }}</span>
                        </div>
                    </li>
                    {{/each}}
                </ul>
            </div>
            <div>
                <h3>Already Imported</h3>

                <ul id="template-users-container" class="cms-users-list">
                    {{#each users}}
                    <li data-content="{{ hepta }}" data-email="{{ email }}" id="modal-{{ hepta }}"
                        class="cms-user-item">
                        <div class="cms-user-cell form-checkbox" style="display:none">
                            <input disabled type="checkbox" id="check-{{ hepta }}" class="cms-editable checkbox-user"
                                value='{ "hepta": "{{ hepta }}", "lastname": "{{ lastname }}", "firstname": "{{ firstname }}", "email": "{{ email }}", "profile": "{{ profile }}" }' />
                        </div>
                        <div class="cms-user-cell cms-user-cell--user cms-users-user">
                            <i class="fa fa-user-o" aria-hidden="true"></i>
                            <span class="cms-userCell-text cms-userCell-text--user">{{ hepta }}</span>
                        </div>
                        <div class="cms-user-cell cms-user-cell--email cms-users-email">
                            <i class="fa fa-envelope-o" aria-hidden="true"></i>
                            <span class="cms-userCell-text cms-userCell-text--email">{{ email }}</span>
                        </div>
                    </li>
                    {{/each}}
                </ul>
            </div>
        </div>
        <!-- /.modal-body -->
        <div class="cms-modal-footer">
            <button type="button" class="btn cms-button--default checkall">Select All</button>
            <button type="submit" class="btn cms-button--default importUsers" data-dismiss="modal">Validate</button>
            <button type="button" class="btn cms-button--default" data-dismiss="modal">Close</button>
        </div>
    </div><!-- /.modal-content -->
</div><!-- /.modal-dialog -->