<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-body">
            <div class="cms-release-tags-header">
                <h2 class="cms-release-tags-title">Multiple Edit</h2>
            </div>

            <hr class="mt-2 mb-3"/>


            <div id="tags">
                <p>Main tag (appears empty in multiple edit mode if you have chosen to see all pages)</p>
                <div class="js-editTags-tags">
                    <select data-width="100%" id="multiple-release-main-tag" class="no-dnd never-dnd selectpicker show-tick" data-live-search="true">{{{mainTagOptions}}}</select>
                </div>
                <hr class="mt-2 mb-3"/>
                <p>Sub tag (appears empty in multiple edit mode)</p>
                <div class="js-editTags-tags">
                    <select data-width="100%" id="multiple-release-sub-tag" class="no-dnd never-dnd selectpicker show-tick" data-live-search="true">{{{subTagOptions}}}</select>
                </div>
            </div>

            <hr class="mt-2 mb-3"/>
            <div class="cms-modal-footer">
                <p style="color:red">If you continue without setting a tag/subtag value, all selected pages's tags/subtags will be removed.</p>
                <button type="button" class="btn cms-button--primary js-btn-edit-tag" data-dismiss="modal">Save Tags & Release</button>
                <button type="button" class="btn cms-button--default js-btn-cancel" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div><!-- /.modal-content -->
</div><!-- /.modal-dialog -->



<script>
  function add_opt(event){
    var value = $(event).parents('div').siblings('.bs-searchbox').find('input').val();
    $(event).parents('div').siblings('.selectpicker').append($("<option></option>").text(value)).val(value);
    $('.selectpicker').selectpicker('refresh');
  }
</script>
