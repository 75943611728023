<div class="col-md-12">
    <div class="cms-contents">
        <div class="cms-contents-buttonContainer">
            <button data-text="New content" id="create-new-content"
                    class="button button--shikoba button--primary createNewContent hidden">
                <i class="fa fa-plus"></i>
                <span>New content</span>
            </button>
        </div>
        <ul id="template-contents-container" class="cms-contents-list">
            {{#each contents}}
                {{#ifCond id '!=' "feature-flip"}}
                    <li data-content="{{ id }}">
                        <h2 class="cms-contents-title">
                            <button class="btn cms-button--editContent editContent hidden" data-content="{{ id }}">
                                <i class="fa fa-pencil-square-o"></i>
                            </button>
                            <span class="cms-contents-folder">&nbsp;{{ id }}</span>
                        </h2>
                    </li>
                {{/ifCond}}
            {{/each}}
        </ul>
    </div>
</div>
