<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-body">
            <div class="cms-release-tags-header">
                <h2 class="cms-release-tags-title">{{title}} </h2>
            </div>
            <hr class="mt-2 mb-3"/>
            <div id="tags">
                <h4>Main tag</h4>
                <div class="js-editTags-tags">
                    <select data-width="100%" id="release-main-tag" class="no-dnd never-dnd selectpicker show-tick" data-live-search="true">{{{mainTagOptions}}}</select>
                </div>
                <hr class="mt-2 mb-3"/>
                <h4>Sub tag</h4>
                <div class="js-editTags-tags">
                    <select data-width="100%" id="release-sub-tag" class="no-dnd never-dnd  selectpicker show-tick" data-live-search="true">{{{subTagOptions}}}</select>
                </div>
            </div>
            <hr class="mt-2 mb-3"/>
            <div class="cms-modal-footer">
                <button type="button" class="btn cms-button--primary js-btn-edit-tag" data-dismiss="modal">Save new tags</button>
                <button type="button" class="btn cms-button--default js-btn-cancel" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>


<script>
  function add_opt(event){
    var value = $(event).parents('div').siblings('.bs-searchbox').find('input').val();
    $(event).parents('div').siblings('.selectpicker').append($("<option></option>").text(value)).val(value);
    $('.selectpicker').selectpicker('refresh');
  }
</script>
