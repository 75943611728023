<div class="cms-importToken">
    <br>
    <h2 class="cms-importToken-title">Import content</h2>
    <br>
    <div class="cms-importToken-row">
        <label for="environment">Environment</label>
        <input type="text" id="environment" class="form-control" value="PROD" disabled>
    </div>
    <br>
    <div class="cms-importToken-row">
        <label for="content"><i class="glyphicon glyphicon-folder-close" aria-hidden="true"></i> &nbsp;Content</label>
        <select id="content" name="selectContent" class="form-control cms-importToken-select">
            <option value="" selected>--</option>
            {{#each contents}}
                <option value="{{ id }}">{{ id }}</option>
            {{/each}}
        </select>
    </div>
    <br>
    <div class="cms-importToken-row">
        <label for="input-include-draft">Include draft releases in import</label>
        {{#includeDraft}}
            <input type="checkbox" id="input-include-draft" class="cms-editable" checked="checked">
        {{else}}
            <input type="checkbox" id="input-include-draft" class="cms-editable">
        {{/includeDraft}}
        <p class="text-warning">/!\ including draft releases can multiply the import time by 3</p>
        <label for="input-include-archived">Include archived releases in import</label>
        {{#includeArchived}}
            <input type="checkbox" id="input-include-archived" class="cms-editable" checked="checked">
        {{else}}
            <input type="checkbox" id="input-include-archived" class="cms-editable">
        {{/includeArchived}}
        <p class="text-warning">/!\ including archived releases can multiply the import time by 6</p>
        <p class="text">NB: archived imports are limited to 6 releases per locale.</p>
    </div>
    <br>
    {{#if contentId}}
        {{#if locales}}
            <div class="cms-importToken-row">
                <label for="locales"><span class="glyphicon glyphicon-flag" aria-hidden="true"></span> Locales</label>
                <br>
                <div class="cms-import-content-select-buttons">
                    <div class="cms-import-content-deselect-button">
                        <ul class="cms-import-content-left-button">
                            <li class="cms-import-content-left-item">
                                <button type="button"
                                        class="js-locales-deselect-all button button--shikoba button--primary button--medium">
                                    <i class="fa fa-square-o" aria-hidden="true"></i>
                                    <span>Deselect all</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div class="cms-import-content-select-button">
                        <ul class="cms-import-content-right-button">
                            <li class="cms-import-content-item">
                                <button type="button"
                                        class="js-locales-select-all button button--shikoba button--primary button--medium">
                                    <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                    <span>Select all</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div style="clear:both;"></div>
                </div>
                <br>
                <div class="cms-import-content-dashboard">
                    {{#each locales}}
                        <div
                                data-locale-id="{{ id }}"
                                class="cms-import-content-dashboard-item clickable-row">
                            <div class="cms-import-content-dashboard-item-content">
                                <div>
                                <span class="cms-import-content-dashboard-content-title">{{ id }} <input
                                        type="checkbox"
                                        name="selectLocale"
                                        class="input-locales-select cms-editable"
                                        id="input-locales-select--{{id}}"
                                        value="{{id}}"/></span>
                                </div>
                            </div>
                        </div>
                    {{/each}}
                </div>
            </div>
        {{/if}}
    {{/if}}
    <br>
    <div class="cms-import-content-select-buttons">
        <div class="cms-import-content-deselect-button">
            <ul class="cms-import-content-left-button">
                <li class="cms-import-content-left-item">
                    <div class="cms-importToken-buttons">
                        <button id="show-locales-button"
                                class="js-showLocales btn cms-button--primary hidden" type="button">Show locales
                        </button>
                    </div>
                </li>
            </ul>
        </div>
        <div class="cms-import-content-select-button">
            <ul class="cms-import-content-right-button">
                <li class="cms-import-content-item">
                    <div class="cms-importToken-buttons">
                        <button class="js-importContent btn cms-button--publish" type="button">Import whole Content
                        </button>
                    </div>
                </li>
            </ul>
        </div>
        <div style="clear:both;"></div>
    </div>
</div>
