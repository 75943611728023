<div class="modal-dialog">
    <div class="modal-content">
        <div class="cms-modal-header--locales">
            <button type="button" class="cms-button-close js-btn-cancel" data-dismiss="modal" aria-label="Close"><span
                    aria-hidden="true">&times;</span></button>
            {{#if nodeJsonPath}}
            <h4 class="cms-modal-title">Import: {{nodeJsonPath}}</h4>
            <h4 class="cms-modal-info">(nodes to select left: <b><span
                    id="node-select-left-count">{{maxNodeSelectCount}}</span></b>)</h4>
            {{else}}
            <h4 class="cms-modal-title">ERROR: a problem was encountered, please try again or contact the
                admin.</h4>
            {{/if}}
        </div>
        <div class="modal-body modal-body--import">
            <div>
                <input type="text" id="searchBarImport"  placeholder="Search for pages..">


                <table id="myTable">
                    {{#if listNodeToSelect.length}}
                    {{#listNodeToSelect}}
                    {{#if [1]}}
                    {{#ifEq [1] "arrayTitle"}}

                    <label>
                        <u><b>{{[0]}}:</b></u>
                    </label>

                    {{else}}


                    <tr>
                        <td style="width:5%;"><input class="js-node-import-checkbox" type="checkbox" value="{{[1]}}"/></td>
                        <td style="width:95%;">{{[0]}}</td>
                    </tr>
                    {{/ifEq}}
                    {{else}}
                    <br/>
                    {{/if}}
                    {{/listNodeToSelect}}
                    {{else}}
                    No result found.
                    {{/if}}
                </table>
            </div>
        </div>
        <div class="cms-modal-footer">
            <button type="button" class="btn cms-button--primary js-btn-import" data-dismiss="modal" disabled>
            {{#if isPathSelection}}
              Next
            {{else}}
              Import
            {{/if}}
            </button>
            <button type="button" class="btn cms-button--default js-btn-cancel" data-dismiss="modal">Cancel</button>
        </div>
    </div><!-- /.modal-content -->
</div><!-- /.modal-dialog -->
