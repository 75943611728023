<div class="row">
    <div class="col-md-12">
        <div class="cms-release-menuTop">
            <div class="cms-menuTop-header">
                <div class="cms-menuTop-header-item last-update-date">
                    <i class="glyphicon glyphicon-calendar" aria-hidden="true"></i>
                    Last update on {{#formatDate release.metadata.last_update_date}}{{/formatDate}}
                </div>
                <div class="cms-menuTop-header-item last-update-user">
                    <i class="glyphicon glyphicon-user" aria-hidden="true"></i>
                    Update by {{release.metadata.last_update_user}}
                </div>
            </div>

            <div class="cms-menuTop-main">
                <div class="cms-subMenu-header">
                    <div class="cms-subMenu-currentFolder">
                        <ul class="cms-left-subMenu-list">
                            <li class="cms-left-subMenu-item">
                                <h1 class="cms-release-title">Release_title:</h1><input type="text"
                                    class="form-control cms-release-input" id="input-release-title"
                                    placeholder="Release title (optional & for CMS display only)..."
                                    value="{{release.title}}" disabled>
                            </li>
                        </ul>
                    </div>
                    <div class="cms-subMenu-indicators">
                        <ul class="cms-subMenu-list">
                            <li class="cms-subMenu-item"><span class="glyphicon glyphicon-folder-open"
                                    aria-hidden="true"></span> <a data-content="{{ release.content_id }}"
                                    class="cms-contents-link js-contents-link">Content:
                                    <em class="cms-subMenu-value">{{release.content_id}}</em></a></li>
                            <li class="cms-subMenu-item"><span class="glyphicon glyphicon-flag"
                                    aria-hidden="true"></span> <a data-content="{{ release.content_id }}"
                                    class="cms-locales-link js-locales-link">Locale:
                                    <em class="cms-subMenu-value">{{release.locale}}</em></a></li>
                            <li class="cms-subMenu-item"><span class="glyphicon glyphicon-file"
                                    aria-hidden="true"></span>
                                <a data-content="{{ release.content_id }}" class="cms-releases-link js-releases-link">
                                    Release:
                                    <em class="cms-subMenu-value">{{#formatId release.id}}{{/formatId}}</em></a>
                            </li>
                            <li class="cms-subMenu-item"><span class="glyphicon glyphicon-dashboard"
                                    aria-hidden="true"></span> Status:
                                <em class="cms-subMenu-value">{{release.status}}</em>
                            </li>
                            {{#if releaseTags}}
                            <li class="cms-subMenu-item"><span class="glyphicon glyphicon-tag"
                                    aria-hidden="true"></span> Tags:
                                <em class="cms-subMenu-value">{{releaseTags}}</em>
                            </li>
                            {{/if}}
                            {{#ifMatch release.content_id CLUBMED_PAGES_CONTENT_ID_REGEX}}
                            <li class="cms-subMenu-item"><span class="glyphicon glyphicon-info-sign"
                                    aria-hidden="true"></span> Total page count:
                                <em class="cms-subMenu-value">{{release.pageCount}}</em>
                            </li>
                            {{/ifMatch}}
                        </ul>
                    </div>
                    <div style="clear:both;"></div>
                </div>
                <div class="js-menuTop-tools">
                    <div class="input-group cms-search">
                        <span class="input-group-addon" id="basic-addon1"><span class="glyphicon glyphicon-search"
                                aria-hidden="true"></span></span>
                        <input type="text" class="form-control typeahead cms-search-input" id="input-release-search"
                            placeholder="search... (minimum length: 2)" autocomplete="off">
                    </div>
                    <div class="cms-subMenu-releaseActions">
                        <form class="form-inline">
                            <button id="button-back" type="button"
                                class="button edit-button button--shikoba button--primary button--medium">
                                <i class="fa fa-arrow-left"></i>
                                {{#if selectedCategory}}
                                <span>Back To Categories</span>
                                {{else}}
                                <span>Back To Releases</span>
                                {{/if}}
                            </button>
                            <button id="button-back-to" type="button"
                                class="button edit-button button--shikoba button--primary button--medium">
                                <i class="fa fa-arrow-left"></i>
                                <span>Previous Page</span>
                            </button>
                            {{#if isSavable}}
                            <button id="button-save-release" type="button"
                                class="button edit-button button--shikoba button--primary button--medium">
                                <i class="fa fa-floppy-o"></i>
                                <span>Save</span>
                            </button>
                            {{/if}}
                            {{#if isPreviewable}}
                            <button id="button-preview-release" type="button"
                                class="button edit-button button--shikoba button--primary button--medium">
                                <i class="fa fa-eye"></i>
                                <span>Preview</span>
                            </button>
                            {{/if}}
                            {{#if isDeletable}}
                            <button id="button-delete-release" type="button"
                                class="button edit-button button--shikoba button--danger button--medium">
                                <i class="fa fa-times"></i>
                                <span>Delete</span>
                            </button>
                            {{/if}}
                            {{#if isRollbackable}}
                            <button type="button"
                                class="button-publish-release button edit-button button--shikoba button--publish button--medium">
                                <i class="fa fa-globe"></i>
                                <span>Re-publish now</span>
                            </button>
                            {{/if}}
                            {{#if isPublishable}}
                            <button type="button"
                                class="button-publish-release button edit-button button--shikoba button--publish button--medium">
                                <i class="fa fa-globe"></i>
                                <span>Publish now</span>
                            </button>
                            <button id="button-programPublish-release" type="button"
                                class="button edit-button button--shikoba button--publish button--medium">
                                <i class="fa fa-hourglass-start"></i>
                                <span id="titleBtn-programPublish">Publish later</span>
                            </button>
                            {{/if}}
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="cms-release-tags-wrapper">
            {{#if isSavable }}
            <div class="cms-release-search-replace">
                <button type="button"
                    class="no-dnd never-dnd btn release-search-replace button button--shikoba button--primary button--medium">
                    <i class="fa fa-search"></i><span class="release-search-replace-text">Search and replace</span>
                </button>
            </div>
            <div class="cms-release-new-main-tag">
                <form class="form-inline">
                    <h3 class="cms-release-tags-sub-title">Add new main tag</h3>
                    <input type="text" class="form-control cms-editable" id="release-new-main-tag" value=""
                        placeholder="Type new main tag here" />
                    <button data-text="New tag" id="create-new-release-tag"
                        class="button button--shikoba button--primary button--medium"
                        data-content-id="{{release.content_id}}" data-locale-id="{{release.locale}}"
                        data-release-id="{{release.id}}">
                        <i class="fa fa-plus"></i>
                        <span>New main tag</span>
                    </button>
                </form>
            </div>
            {{else}}
            <div class="cms-release-search-replace">
                <button type="button"
                    class="no-dnd never-dnd btn release-search-replace button button--shikoba button--primary button--medium">
                    <i class="fa fa-search"></i><span class="release-search-replace-text">Search</span></button>
            </div>
            {{/if}}
            <div class="clearfix"></div>
            <div class="cms-release-tags-label">
                <h3 class="cms-release-tags-sub-title">Select Tag</h3>
            </div>
        </div>
        <div class="clearfix"></div>
        <ul id="template-release-tags-container" class="cms-release-tags-list">
            {{#each tagsInfo}}
            <li class="cms-release-tags-row" data-main-tag="{{mainTag}}">
                <h3 class="cms-release-tags-main-element">
                    {{#ifEq mainTag "ALL"}}
                    {{else}}
                    {{#if isSavable }}
                    <button class="btn cms-button--editTags editTags" data-content-id="{{contentId}}"
                        data-locale-id="{{localeId}}" data-release-id="{{releaseId}}" data-main-tag="{{mainTag}}">
                        <i class="fa fa-pencil-square-o"></i>
                    </button>
                    {{/if}}
                    {{#ifNotEq mainTag "UNTAGGED"}}
                    {{#ifNotEq subTags.length 0}}
                    <div class="cms-dropdown">
                        <button class="btn cms-button--dropdownTags dropdownTags" data-main-tag="{{mainTag}}">
                            <i class="fa fa-angle-double-down" aria-hidden="true"></i>
                        </button>
                        <div id="dropdown-{{mainTag}}" class="cms-dropdown--content hidden">
                            {{#each subTags}}
                            {{#if isSavable }}
                            <a href="#" class="cms-release-sub-tags-row" data-main-tag="{{mainTag}}"
                                data-sub-tag="{{subTag}}">{{ subTagStr }}
                                <button class="btn cms-button--editSubTags editSubTags" data-content-id="{{contentId}}"
                                    data-locale-id="{{localeId}}" data-release-id="{{releaseId}}"
                                    data-main-tag="{{mainTag}}" data-sub-tag="{{subTag}}">
                                    <i class="fa fa-pencil-square-o"></i>
                                </button>
                            </a>
                            {{else}}
                            <a href="#" class="cms-release-sub-tags-row" data-main-tag="{{mainTag}}"
                                data-sub-tag="{{subTag}}">{{ subTagStr }}
                            </a>
                            {{/if}}
                            {{/each}}
                        </div>
                    </div>
                    {{/ifNotEq}}
                    {{/ifNotEq}}
                    {{/ifEq}}
                    <span class="cms-release-tags-folder">{{ mainTagStr }}</span>
                </h3>
            </li>
            {{/each}}
        </ul>
    </div>
</div>
</div>
</div>