<div class="cms-background"></div>
<div class="cms-welcome">
    <h2 class="cms-welcome-title">Welcome to the ClubMed CMS</h2>
    {{#if account.access_token}}
        <h3>{{account.name}}</h3>
    {{else}}
        {{#if account.access_denied}}
            <h3><b>Warning: you do not have the LDAP permission to access the CMS,</br> please contact 3950 or your manager to request access to the CMS.</b></h3>
            <h3><b>Please note that you remain logged in OIDC. If you want to log out or connect to another account:<br> go to the OIDC page and clean your cookies & cache on it or enter new credentials.</b></h3>
            <button id="button-OIDC" type="button" class="button button--shikoba button--primary">
                <i class="fa fa-external-link" aria-hidden="true"></i>
                <span>Go to OIDC</span>
            </button>
        {{else}}
            <br>
            <button id="button-login" type="button" class="button button--shikoba button--primary">
                <i class="fa fa-sign-in" aria-hidden="true"></i>
                <span>Login</span>
            </button>
        {{/if}}
    {{/if}}
    {{#if account.access_token}}
        {{#if account.profile}}
        {{else}}
            <h3><b><u>Warning:</u></b> you do not have any CMS permission yet or your email address may have changed in LDAP,</br> please contact 3950 if it needs to be changed or the CMS support if it is correct.</h3>
            {{#if account.email}}
                <h3><b><u>Here is the email address registered in your LDAP account. Please include it in your request:</u></b></br>{{account.email}}</h3>
            {{else}}
                <h3><b><u>Please include your email address in your request.</u></b></h3>
            {{/if}}
        {{/if}}
    {{/if}}
</div>
