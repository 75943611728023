<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-body">
            <div class="cms-release-tags-header">
                {{#if isSavable}}
                    <h2 class="cms-release-search-replace-title">Search and replace</h2>
                {{else}}
                    <h2 class="cms-release-search-replace-title">Search</h2>
                {{/if}}
            </div>
            <div class="cms-release-search-json-download hidden">
                <button type="button" class="button button--shikoba button--primary button--medium js-btn-dl-json"
                        data-dismiss="modal"><i class="fa fa-download"></i>Download JSON
                </button>
                <button type="button" class="button button--shikoba button--primary button--medium js-btn-dl-xlsx"
                        data-dismiss="modal"><i class="fa fa-download"></i>Download XLSX
                </button>
            </div>
            <div class="input-group cms-search">
                <input type="text" class="form-control cms-modal-search-input" id="input-release-modal-search"
                       placeholder="Search... (minimum length: 2)">
                <span class="button--primary input-group-addon" id="basic-addon-search"><span
                        class="glyphicon glyphicon-search" aria-hidden="true"></span> Search</span>
            </div>
            {{#if isSavable}}
                <div class="input-group cms-replace">
                    <input type="text" class="form-control cms-modal-replace-input" id="input-release-modal-replace"
                           placeholder="Replace..." disabled>
                    <span class="input-group-addon" id="basic-addon-replace"><span
                            class="glyphicon glyphicon-pencil" aria-hidden="true"></span> Replace</span>
                </div>
            {{/if}}
            <div class="cms-release-search--error hidden">
                <div class="cms-release-search-field--error"></div>
            </div>
            <div class="cms-release-search--warning hidden">
                <div class="cms-release-search-field--warning"></div>
            </div>
            <div class="modal-body">
                <ul class="format-choices">
                    <li>
                        <button class="format-button active" id="format-button-1">JSON</button>
                    </li>
                </ul>
                <div class="cms-jsoneditor jsoneditor-edit-node" id="node-jsoneditor">
                </div>
                <div class="cms-modal-footer">
                    {{#if isSavable}}
                        <button type="button" class="btn cms-button--primary js-btn-replace" data-dismiss="modal"
                                disabled>
                            Apply replace and save
                        </button>
                    {{/if}}
                    <button type="button" class="btn cms-button--default js-btn-cancel" data-dismiss="modal">Back
                    </button>
                </div>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div>
