<div class="col-md-12">
    <div class="cms-migrateContent">
        <div class="cms-migrateContent-header">
            <h2 class="cms-migrateContent-title">Migrate content V2</h2>
        </div>
        <ul class="cms-migrateContent-list">
            <li>
                <form class="form-inline cms-migrateContent-buttons">
                    <button id="button-back" type="button"
                            class="button edit-button button--shikoba button--primary button--medium">
                        <i class="fa fa-arrow-left"></i>
                        <span>Back</span>
                    </button>
                    <button
                            id="button-back-to" type="button"
                            class="button edit-button button--shikoba button--primary button--medium">
                        <i class="fa fa-arrow-left"></i>
                        <span>Previous Page</span>
                    </button>
                    <button id="button-apply-migrate-content" type="button"
                            class="button button--shikoba button--medium button--publish">
                        <i class="fa fa-check"></i>
                        <span>Apply migrations</span>
                    </button>
                </form>
            </li>
        </ul>
    </div>
</div>
<div class="col-md-6">
    <div class="cms-jsoneditor" id="jsoneditor">
        <p class="cms-jsoneditor-title">Migration data</p>
    </div>
</div>
<div class="col-md-6">
    <div class="cms-migrateContent-error-display hidden">
        <p class="cms-migrateContent-error-title">Errors</p>
        <ul class="cms-migrateContent-error-list">
        </ul>
    </div>
    <div class="cms-migrateContent-result-display hidden">
        <p class="cms-migrateContent-result-title">Results</p>
        <div class="cms-migrateContent-result-content"></div>
    </div>
</div>
