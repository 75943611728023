<div class="cms-addToken">

  <h2 class="cms-addToken-title">Add token</h2>
  <input type="text" class="js-addToken-field form-control cms-editable" placeholder="add token here">
  <p class="cms-addToken-error js-addToken-error"></p>

  <div class="cms-addToken-buttons">
    <button class="button button--shikoba button--publish js-validateToken" type="button" >
      <i class="fa fa-check"></i>
      <span>Validate</span>
    </button>
  </div>

</div>
