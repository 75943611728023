<div class="col-md-12">
    <div class="cms-migrateContent">
        <div class="cms-migrateContent-header">
            <h2 class="cms-migrateContent-title">Migrate content V1</h2>
        </div>
        <ul class="cms-migrateContent-list">
            <li>
                <form class="form-inline cms-migrateContent-buttons">
                    <button id="button-back" type="button"
                            class="button edit-button button--shikoba button--primary button--medium">
                        <i class="fa fa-arrow-left"></i>
                        <span>Back</span>
                    </button>
                    <button
                            id="button-back-to" type="button"
                            class="button edit-button button--shikoba button--primary button--medium">
                        <i class="fa fa-arrow-left"></i>
                        <span>Previous Page</span>
                    </button>
                    <button id="button-apply-migrate-content" type="button"
                            class="button button--shikoba button--medium button--publish">
                        <i class="fa fa-check"></i>
                        <span>Apply migration</span>
                    </button>
                </form>
            </li>
        </ul>
    </div>
</div>
<div class="col-md-6">
    <label for="content"><i class="glyphicon glyphicon-folder-close" aria-hidden="true"></i> &nbsp;Content</label>
    <select id="content" name="selectContent" class="form-control cms-massOperations-select">
        <option value="" selected>--</option>
        {{#each contents}}
            <option value="{{ id }}">{{ id }}</option>
        {{/each}}
    </select>
    <br>
    <label for="locales"><span class="glyphicon glyphicon-flag" aria-hidden="true"></span> Locales (if none selected, applies migration to ALL locales)</label>
    <select id="locales" name="selectLocales" class="form-control cms-massOperations-select cms-select-liveSearch selectpicker" data-live-search="true" data-actions-box="true" multiple disabled>
    </select>
    <br>
    <br>
    <div class="cms-jsoneditor" id="jsoneditor">
        <p class="cms-jsoneditor-title">Migration data</p>
    </div>
</div>
<div class="col-md-6">
    <div class="cms-migrateContent-error-display hidden">
        <p class="cms-migrateContent-error-title">Errors</p>
        <ul class="cms-migrateContent-error-list">
        </ul>
    </div>
    <div class="cms-migrateContent-result-display hidden">
        <p class="cms-migrateContent-result-title">Results</p>
        <div class="cms-migrateContent-result-content"></div>
    </div>
</div>
