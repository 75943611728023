<div class="col-md-12">
    <div class="cms-editTags">
        <div class="cms-release-tags-header">
            <h2 class="cms-release-tags-title">{{release.content_id}} <a data-content="{{release.content_id}}" class="cms-locales-link js-locales-link">[{{release.locale}}]</a> {{#formatId release.id}}{{/formatId}} ({{releaseTags}})</h2>
        </div>
        <ul class="cms-editTags-list">
            <li>
                <div id="tags js-editTags-main-tag">
                    <p>Main tag</p>
                    <div class="cms-editTags-tags">
                        {{#ifEq tags "UNTAGGED"}}
                            <input type="text" class="form-control cms-editable"
                                   id="release-main-tag" value=""/>
                        {{else}}
                            <input type="text" class="form-control cms-editable"
                                   id="release-main-tag" value="{{mainTagStr}}"/>
                        {{/ifEq}}
                    </div>
                </div>
                <div id="js-editTags-sub-tag">
                    <p class="hidden">Sub tag</p>
                    <div class="cms-editTags-tags hidden">
                        <input type="text" class="form-control cms-editable"
                               id="release-sub-tag" value="{{subTagStr}}"/>
                    </div>
                </div>
            </li>
        </ul>
        <div class="js-menuTop-tools">
            <div class="cms-subMenu-releaseActions">
                <form class="form-inline">
                    <button id="button-back" type="button"
                            class="button edit-button button--shikoba button--primary button--medium">
                        <i class="fa fa-arrow-left"></i>
                        <span>Back</span>
                    </button>
                    <button
                            id="button-back-to" type="button"
                            class="button edit-button button--shikoba button--primary button--medium">
                        <i class="fa fa-arrow-left"></i>
                        <span>Previous Page</span>
                    </button>
                    {{#ifEq tags "UNTAGGED"}}
                    {{else}}
                        <button id="button-tags-remove" type="button"
                                class="button button--shikoba button--danger button--medium removeTags">
                            <i class="fa fa-close"></i>
                            {{#ifExists subTagStr}}
                                <span>Delete sub tag</span>
                            {{else}}
                                <span>Delete main tag</span>
                            {{/ifExists}}
                        </button>
                        <button id="button-tags-disable" type="button"
                                class="button button--shikoba button--warning button--medium disableElements">
                            <i class="fa fa-square-o"></i>
                            <span>Disable all {{tagsProperty}}</span>
                        </button>
                        <button id="button-tags-enable" type="button"
                                class="button button--shikoba button--publish button--medium enableElements">
                            <i class="fa fa-check-square-o"></i>
                            <span>Enable all {{tagsProperty}}</span>
                        </button>
                    {{/ifEq}}
                    <button id="button-tags-edit" type="button"
                            class="button button--shikoba button--primary button--medium editTags">
                        <i class="fa fa-floppy-o"></i>
                        <span>Save</span>
                    </button>
                </form>
            </div>
        </div>
        <div id="js-addTag-sub-tag">
            <p>Add new sub tag</p>
            <div class="cms-addTag-sub-tag">
                <input type="text" class="form-control cms-editable"
                       id="release-new-sub-tag" value=""/>
            </div>
            <div class="js-menuTop-tools">
                <div class="cms-subMenu-releaseActions">
                    <form class="form-inline">
                        <button id="button-tags-add-sub-tag" type="button"
                                class="button button--shikoba button--primary button--medium addSubTag">
                            <i class="fa fa-plus"></i>
                            <span>New sub tag</span>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
