<div class="modal-dialog cms-modal-dialog--locales">
    <div class="modal-content">
        <div class="cms-modal-header--locales">
            <button type="button" class="cms-button-close" data-dismiss="modal" aria-label="Close"><span
                    aria-hidden="true">&times;</span></button>
            <h4 class="cms-modal-title">Choose your Language-Country</h4>
        </div>
        <div class="modal-body">
            {{#areas}}
                <div class="cms-modal-section">
                    <h5 class="cms-modal-subtitle">{{name}}</h5>
                    <ul class="cms-modal-group">
                        {{#countries}}
                            {{#ifMoreThanOne locales}}
                                <li class="cms-modal-item {{#oneLocaleAuthorized locales}}authorized{{/oneLocaleAuthorized}}">
                                    <span class="FlagIcon FlagIcon--{{iso3166}}"></span>
                                    <span class="country-name {{#oneLocaleAuthorized locales}}authorized{{/oneLocaleAuthorized}}">{{name}}</span>
                                    {{#locales}}
                                        {{#unless @first}}
                                            /
                                        {{/unless}}
                                        <a data-content="{{../../../../content_id}}" data-id="{{id}}"
                                           class="cms-modal-releases js-modal-releases cms-modal-releases--locale {{#if authorized}}authorized{{/if}} {{#if exists}}exist{{/if}}"
                                           data-authorized="{{authorized}}" {{#unless authorized}}disabled{{/unless}}
                                           {{#unless exists}}data-create="true"{{/unless}}>{{label}}</a>
                                    {{/locales}}
                                </li>
                            {{else}}
                                {{#locales}}
                                    <li class="cms-modal-item {{#if authorized}}authorized{{/if}}">
                                        <span class="FlagIcon FlagIcon--{{../iso3166}}"></span>
                                        <a data-content="{{../../../../content_id}}"
                                           data-id="{{id}}"
                                           class="cms-modal-releases js-modal-releases country-name {{#if authorized}}authorized{{/if}} {{#if exists}}exist{{/if}}"
                                           data-authorized="{{authorized}}" {{#unless authorized}}disabled{{/unless}}
                                           {{#unless exists}}data-create="true"{{/unless}}>{{../name}}</a>
                                    </li>
                                {{/locales}}
                            {{/ifMoreThanOne}}
                        {{/countries}}
                    </ul>
                </div>
            {{/areas}}
        </div>
        <!-- /.modal-body -->
        <div class="cms-modal-footer">
            <button type="button" class="btn cms-button--default" data-dismiss="modal">Close</button>
        </div>
    </div><!-- /.modal-content -->
</div><!-- /.modal-dialog -->
