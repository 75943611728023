<style>
  .docs-buttons>.btn-group {
    margin-bottom: 0.5rem;
    margin-right: 0.25rem;
  }
</style>

<div class="modal-dialog cms-modal-dialog modal-lg">
  <div class="modal-content">
    <div class="cms-modal-header --addToken">
      <button type="button" class="cms-button-close" data-dismiss="modal" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
      <h3 class="cms-modal-title">Edit Image</h3>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col-md-9">
          <div class="cms-imageCropper">
            <div class="img-container">
              <img src="{{ imageSrc }}" alt="Picture" crossorigin="anonymous">
            </div>
          </div>
        </div>
        <div class="col-md-3">
          {{!-- <h3>Preview:</h3> --}}
          <div class="docs-preview clearfix">
            <div class="img-preview preview-lg"></div>
            <div class="img-preview preview-md"></div>
            <div class="img-preview preview-sm"></div>
            <div class="img-preview preview-xs"></div>
          </div>
        </div>
      </div>
      <div class="row container-fluid" id="actions">
        {{!-- <div class="col"> --}}
          <div class="row docs-buttons">
            <!-- <h3>Toolbar:</h3> -->
            <div class="btn-group">
              <button type="button" class="btn btn-primary" data-method="setDragMode" data-option="move" title="Move">
                <span class="docs-tooltip" data-toggle="tooltip" title="cropper.setDragMode(&quot;move&quot;)">
                  <span class="fa fa-arrows-alt"></span>
                </span>
              </button>
              <button type="button" class="btn btn-primary" data-method="setDragMode" data-option="crop" title="Crop">
                <span class="docs-tooltip" data-toggle="tooltip" title="cropper.setDragMode(&quot;crop&quot;)">
                  <span class="fa fa-crop"></span>
                </span>
              </button>
            </div>

            <div class="btn-group">
              <button type="button" class="btn btn-primary" data-method="zoom" data-option="0.1" title="Zoom In">
                <span class="docs-tooltip" data-toggle="tooltip" title="cropper.zoom(0.1)">
                  <span class="fa fa-search-plus"></span>
                </span>
              </button>
              <button type="button" class="btn btn-primary" data-method="zoom" data-option="-0.1" title="Zoom Out">
                <span class="docs-tooltip" data-toggle="tooltip" title="cropper.zoom(-0.1)">
                  <span class="fa fa-search-minus"></span>
                </span>
              </button>
            </div>

            <div class="btn-group">
              <button type="button" class="btn btn-primary" data-method="move" data-option="-10" data-second-option="0"
                title="Move Left">
                <span class="docs-tooltip" data-toggle="tooltip" title="cropper.move(-10, 0)">
                  <span class="fa fa-arrow-left"></span>
                </span>
              </button>
              <button type="button" class="btn btn-primary" data-method="move" data-option="10" data-second-option="0"
                title="Move Right">
                <span class="docs-tooltip" data-toggle="tooltip" title="cropper.move(10, 0)">
                  <span class="fa fa-arrow-right"></span>
                </span>
              </button>
              <button type="button" class="btn btn-primary" data-method="move" data-option="0" data-second-option="-10"
                title="Move Up">
                <span class="docs-tooltip" data-toggle="tooltip" title="cropper.move(0, -10)">
                  <span class="fa fa-arrow-up"></span>
                </span>
              </button>
              <button type="button" class="btn btn-primary" data-method="move" data-option="0" data-second-option="10"
                title="Move Down">
                <span class="docs-tooltip" data-toggle="tooltip" title="cropper.move(0, 10)">
                  <span class="fa fa-arrow-down"></span>
                </span>
              </button>
            </div>

            <div class="btn-group">
              <button type="button" class="btn btn-primary" data-method="rotate" data-option="-45" title="Rotate Left">
                <span class="docs-tooltip" data-toggle="tooltip" title="cropper.rotate(-45)">
                  <span class="fa fa-undo"></span>
                </span>
              </button>
              <button type="button" class="btn btn-primary" data-method="rotate" data-option="45" title="Rotate Right">
                <span class="docs-tooltip" data-toggle="tooltip" title="cropper.rotate(45)">
                  <span class="fa fa-repeat"></span>
                </span>
              </button>
            </div>

            <div class="btn-group">
              <button type="button" class="btn btn-primary" data-method="scaleX" data-option="-1"
                title="Flip Horizontal">
                <span class="docs-tooltip" data-toggle="tooltip" title="cropper.scaleX(-1)">
                  <span class="fa fa-arrows-h"></span>
                </span>
              </button>
              <button type="button" class="btn btn-primary" data-method="scaleY" data-option="-1" title="Flip Vertical">
                <span class="docs-tooltip" data-toggle="tooltip" title="cropper.scaleY(-1)">
                  <span class="fa fa-arrows-v"></span>
                </span>
              </button>
            </div>

            <div class="btn-group">
              <button type="button" class="btn btn-primary" data-method="reset" title="Reset">
                <span class="docs-tooltip" data-toggle="tooltip" title="cropper.reset()">
                  <span class="fa fa-refresh"></span>
                </span>
              </button>
            </div>

            <button type="button" class="btn btn-secondary" data-method="moveTo" data-option="0">
              <span class="docs-tooltip" data-toggle="tooltip" title="cropper.moveTo(0)">
                Move to [0,0]
              </span>
            </button>
            <button type="button" class="btn btn-secondary" data-method="zoomTo" data-option="1">
              <span class="docs-tooltip" data-toggle="tooltip" title="cropper.zoomTo(1)">
                Zoom to 100%
              </span>
            </button>
            <button type="button" class="btn btn-secondary" data-method="rotateTo" data-option="180">
              <span class="docs-tooltip" data-toggle="tooltip" title="cropper.rotateTo(180)">
                Rotate 180°
              </span>
            </button>
          </div><!-- /.docs-buttons -->
          <div class="row docs-toggles">
            <!-- <h3>Toggles:</h3> -->
            <div class="btn-group d-flex flex-nowrap" data-toggle="buttons">
              <label class="btn btn-primary active">
                <input type="radio" class="sr-only" id="aspectRatio1" name="setAspectRatio" value="1.7777777777777777">
                <span class="docs-tooltip" data-toggle="tooltip" title="aspectRatio: 16 / 9">
                  16:9
                </span>
              </label>
              <label class="btn btn-primary">
                <input type="radio" class="sr-only" id="aspectRatio2" name="setAspectRatio" value="1.3333333333333333">
                <span class="docs-tooltip" data-toggle="tooltip" title="aspectRatio: 4 / 3">
                  4:3
                </span>
              </label>
              <label class="btn btn-primary">
                <input type="radio" class="sr-only" id="aspectRatio3" name="setAspectRatio" value="1">
                <span class="docs-tooltip" data-toggle="tooltip" title="aspectRatio: 1 / 1">
                  1:1
                </span>
              </label>
              <label class="btn btn-primary">
                <input type="radio" class="sr-only" id="aspectRatio4" name="setAspectRatio" value="0.6666666666666666">
                <span class="docs-tooltip" data-toggle="tooltip" title="aspectRatio: 2 / 3">
                  2:3
                </span>
              </label>
              <label class="btn btn-primary">
                <input type="radio" class="sr-only" id="aspectRatio4" name="setAspectRatio" value="0.5625">
                <span class="docs-tooltip" data-toggle="tooltip" title="aspectRatio: 9 / 16">
                  9:16
                </span>
              </label>
              <label class="btn btn-primary">
                <input type="radio" class="sr-only" id="aspectRatio5" name="setAspectRatio" value="NaN">
                <span class="docs-tooltip" data-toggle="tooltip" title="aspectRatio: NaN">
                  Free
                </span>
              </label>
            </div>
          </div> <!-- /.docs-toggles -->
        </div>
        {{!-- </div> --}}
    </div>

    <div class="cms-modal-footer">
      <button type="button" class="btn cms-button--default" data-dismiss="modal">Cancel</button>
      <button type="button" class="btn cms-button--default js-saveImage">Save</button>
    </div>
  </div>
</div>