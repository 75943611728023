<section id="section-display-release">
    <div class="row">
        <div class="col-md-12">
            <div class="cms-release-menuTop">
                <div class="cms-menuTop-header">
                    <div class="cms-menuTop-header-item last-update-date">
                        <i class="glyphicon glyphicon-calendar" aria-hidden="true"></i>
                        Last update on {{#formatDate release.metadata.last_update_date}}{{/formatDate}}
                    </div>
                    <div class="cms-menuTop-header-item last-update-user">
                        <i class="glyphicon glyphicon-user" aria-hidden="true"></i>
                        Update by {{release.metadata.last_update_user}}
                    </div>
                </div>

                <div class="cms-menuTop-main">
                    <div class="cms-subMenu-header">
                        <div class="cms-subMenu-currentFolder">
                            <ul class="cms-left-subMenu-list">
                                <li class="cms-left-subMenu-item"><h1 class="cms-release-title">Release_title:</h1>
                                    <input
                                            type="text" class="form-control cms-release-input" id="input-release-title"
                                            placeholder="Release title (optional & for CMS display only)..."
                                            value="{{release.title}}" disabled></li>
                            </ul>
                        </div>
                        <div class="cms-subMenu-indicators">
                            <ul class="cms-subMenu-list">
                                <li class="cms-subMenu-item">
                                    <span
                                            class="glyphicon glyphicon-folder-open"
                                            aria-hidden="true">
                                    </span> Content:
                                    <a
                                            data-content="{{ release.content_id }}"
                                            class="cms-contents-link js-contents-link">

                                    <em class="cms-subMenu-value">{{release.content_id}}</em>
                                    </a>

                                </li>


                                {{#ifCond release.content_id '!=' "feature-flip"}}
                                <li class="cms-subMenu-item"><span
                                        class="glyphicon glyphicon-flag"
                                        aria-hidden="true"></span>
                                    <a
                                            data-content="{{ release.content_id }}"
                                            class="cms-locales-link js-locales-link">
                                        Locale:
                                        <em class="cms-subMenu-value">{{release.locale}}</em>
                                    </a>

                                </li>
                                {{/ifCond}}


                                <li class="cms-subMenu-item"><span
                                        class="glyphicon glyphicon-file"
                                        aria-hidden="true"></span>  <a
                                        data-content="{{ release.content_id }}"
                                        class="cms-releases-link js-releases-link">Release:
                                    <em class="cms-subMenu-value">{{#formatId release.id}}{{/formatId}}</em></a>
                                </li>
                                <li class="cms-subMenu-item"><span
                                        class="glyphicon glyphicon-dashboard"
                                        aria-hidden="true"></span> Status:
                                    <em class="cms-subMenu-value">{{release.status}}</em></li>
                                {{#ifCond releaseTags '&&!' subTag }}
                                <li class="cms-subMenu-item"><span
                                        class="glyphicon glyphicon-tag"
                                        aria-hidden="true"></span> Tags:
                                    <em class="cms-subMenu-value"> {{releaseTags}}</em></li>
                                {{/ifCond}}
                                {{#if subTag}}
                                <li class="cms-subMenu-item"><span
                                        class="glyphicon glyphicon-tag"
                                        aria-hidden="true"></span> Tags:
                                    <em class="cms-subMenu-value"><a
                                            id="main-tag-link"
                                            data-content="{{ release.content_id }}"
                                            mainTag="{{mainTag}}"
                                            class="cms-tags-link js-tags-link">{{mainTag}}</a>  >  {{subTag}}</em></li>
                                {{/if}}
                                {{#ifMatch release.content_id CLUBMED_PAGES_CONTENT_ID_REGEX}}
                                  <li class="cms-subMenu-item"><span
                                    class="glyphicon glyphicon-info-sign"
                                    aria-hidden="true"></span> Total page count:
                                    <em class="cms-subMenu-value">{{release.pageCount}}</em></li>
                                {{/ifMatch}}
                            </ul>
                        </div>
                        <div style="clear:both;"></div>
                    </div>
                    <div class="js-menuTop-tools">
                        <div class="input-group cms-search">
                            <span class="input-group-addon" id="basic-addon1"><span
                                    class="glyphicon glyphicon-search" aria-hidden="true"></span></span>
                            <input
                                    type="text" class="form-control typeahead cms-search-input" id="input-release-search"
                                    placeholder="search... (minimum length: 2)" autocomplete="off">
                        </div>
                        <div class="cms-subMenu-releaseActions">
                            <form class="form-inline">
                                <button
                                        id="button-back" type="button"
                                        class="button edit-button button--shikoba button--primary button--medium">
                                    <i class="fa fa-arrow-left"></i>
                                    <span>Back To Release's Tags</span>
                                </button>

                                <button
                                        id="button-back-to" type="button"
                                        class="button edit-button button--shikoba button--primary button--medium">
                                    <i class="fa fa-arrow-left"></i>
                                    <span>Previous Page</span>
                                </button>

                                {{#if isSavable}}
                                <button
                                        id="button-save-release" type="button"
                                        class="button edit-button button--shikoba button--primary button--medium">
                                    <i class="fa fa-floppy-o"></i>
                                    <span>Save</span>
                                </button>
                                {{/if}}
                                {{#if isPreviewable}}
                                <button
                                        id="button-preview-release" type="button"
                                        class="button edit-button button--shikoba button--primary button--medium">
                                    <i class="fa fa-eye"></i>
                                    <span>Preview</span>
                                </button>
                                {{/if}}
                                {{#if isDeletable}}
                                <button
                                        id="button-delete-release" type="button"
                                        class="button edit-button button--shikoba button--danger button--medium">
                                    <i class="fa fa-times"></i>
                                    <span>Delete</span>
                                </button>
                                {{/if}}
                                {{#if isRollbackable}}
                                <button
                                        type="button"
                                        class="button-publish-release button edit-button button--shikoba button--publish button--medium">
                                    <i class="fa fa-globe"></i>
                                    <span>Re-publish now</span>
                                </button>
                                {{/if}}
                                {{#if isPublishable}}
                                <button
                                        type="button"
                                        class="button-publish-release button edit-button button--shikoba button--publish button--medium">
                                    <i class="fa fa-globe"></i>
                                    <span>Publish now</span>
                                </button>
                                <button
                                        id="button-programPublish-release" type="button"
                                        class="button edit-button button--shikoba button--publish button--medium">
                                    <i class="fa fa-hourglass-start"></i>
                                    <span id="titleBtn-programPublish">Publish later</span>
                                </button>
                                {{/if}}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div id="tree-menu">
                {{#if isSavable }}
                <button
                        type="button"
                        class="no-dnd never-dnd btn release-search-replace button button--shikoba button--primary button--little">
                    <i class="fa fa-search"></i><span class="release-search-replace-text">Search and replace</span>
                </button>
                {{else}}
                <button
                        type="button"
                        class="no-dnd never-dnd btn release-search-replace button button--shikoba button--primary button--little">
                    <i class="fa fa-search"></i><span class="release-search-replace-text">Search</span>
                </button>
                {{/if}}


                <span id="grouped-action-bar" hidden>
                    <button
                            id="edit-all"
                            type="button"
                            class="no-dnd never-dnd btn edit-all button button--shikoba button--primary button--little">
                        <i class="fa fa-edit"></i><span class="release-search-replace-text">Edit Tags for selected</span>
                    </button>

                    <button
                            id="program-all"
                            type="button"
                            class="no-dnd never-dnd btn program-all button button--shikoba button--primary button--little">
                        <i class="fa fa-clock-o"></i><span class="release-search-replace-text">Program dates for selected</span>
                    </button>

                    <button
                            id="toggle-all"
                            type="button"
                            class="no-dnd never-dnd btn toggle-all button button--danger button--primary button--little">
                        <i class="fa fa-eye-slash"></i><span class="release-search-replace-text">Enable/Disable selected</span>
                    </button>

                    <button
                            id="delete-all"
                            type="button"
                            class="no-dnd never-dnd btn delete-all button button--danger button--primary button--little">
                        <i class="fa fa-trash-o"></i><span class="release-search-replace-text">Delete selected</span>
                    </button>

                          <button
                                  id="duplicate-all"
                                  type="button"
                                  class="no-dnd never-dnd btn duplicate-all button button--shikoba button--primary button--little">
                        <i class="fa fa-files-o"></i><span class="release-search-replace-text">Duplicate selected</span>
                    </button>

                </span>


            </div>
            <div id="tree-visualiser" class="tree-hidden"></div>
        </div>
    </div>

</section>
