<section id="section-display-releases">
    <div class="row">
        <div class="col-md-12">
            <div class="cms-releases">
                <div class="cms-releases-header">
                    <h2 class="cms-releases-title">{{content_id}}
                        {{#ifCond content_id '!=' "feature-flip"}}
                            <a data-content="{{content_id}}" class="cms-locales-link js-locales-link">[{{locale_id}}]</a>
                        {{/ifCond}}
                    </h2>
                    <button disabled data-text="New release" id="create-new-release"
                            class="button button--shikoba button--primary"
                            data-content-id="{{content_id}}" data-locale-id="{{locale_id}}">
                        <i class="fa fa-plus"></i>
                        <span>New release</span>
                    </button>
                    <div class="cms-releases-buttons">
                        <button id="button-back"
                                class="button button--shikoba button--primary button--medium releases">
                            <i class="fa fa-arrow-left"></i>
                            <span>Back To Contents</span>
                        </button>
                        <button
                                id="button-back-to" type="button"
                                class="button edit-button button--shikoba button--primary button--medium">
                            <i class="fa fa-arrow-left"></i>
                            <span>Previous Page</span>
                        </button>
                        <button id="archived"
                                class="button button--shikoba button--primary button--medium archived hidden">
                            <i class="fa fa-archive"></i>
                            <span>Archived</span>
                        </button>
                        <button id="showAllArchived"
                                class="button button--shikoba button--primary button--medium allArchived">
                            <i class="fa fa-archive"></i>
                            <span>Show all archived</span>
                        </button>
                    </div>
                </div>

                <div class="cms-release-dashboard">
                    {{#releases}}
                        <div
                                data-content-id="{{content_id}}"
                                data-live-number-of-pages="{{livePageCount}}"
                                data-locale-id="{{locale}}"
                                data-release-id="{{id}}"
                                data-status="{{status}}"
                                class="cms-dashboard-item clickable-row"
                        >
                            <div class="cms-dashboard-item-title cms-dashboard-item-title--{{status}}">{{status}}</div>
                            <div class="cms-dashboard-item-content">
                                <div>
                                    <span class="cms-dashboard-content-title">Release</span>
                                    <span>{{#formatId id}}{{/formatId}}</span>
                                </div>
                                <div>
                                    <span class="cms-dashboard-content-title">Title</span>
                                    <span class="js-cms-table-title">{{title}}</span>
                                </div>
                                <div>
                                    <span class="cms-dashboard-content-title">Publication date</span>
                                    <span class="js-cms-table-publish_date">{{#releaseDateMsg
                                            this}}{{/releaseDateMsg}}</span>
                                </div>
                                <div>
                                    <span class="cms-dashboard-content-title">Last update</span>
                                    <span class="js-cms-table-last_update_date">{{#formatDate
                                            metadata.last_update_date}}{{/formatDate}}</span>
                                </div>
                                <div>
                                    <span class="cms-dashboard-content-title">Updated by</span>
                                    <span class="js-cms-table-last_update_user">{{metadata.last_update_user}}</span>
                                </div>
                                {{#ifMatch content_id CLUBMED_PAGES_CONTENT_ID_REGEX}}
                                  {{#ifCond pageCount '>' -1}}
                                    {{#ifCond pageCountUsedForDiff '>' -1}}
                                      <div>
                                        {{#ifCond status '===' "draft"}}
                                          <span class="cms-dashboard-content-title">Page count diff with live</span>
                                        {{/ifCond}}
                                        {{#ifCond status '!==' "draft"}}
                                          <span class="cms-dashboard-content-title">Page count diff with previous live</span>
                                        {{/ifCond}}
                                        {{#ifCond pageCountDiff '>' 0}}
                                          <span class="js-cms-table-total_nb_pages_green">+{{pageCountDiff}}</span>
                                        {{/ifCond}}
                                        {{#ifCond pageCountDiff '==' 0}}
                                          <span class="js-cms-table-total_nb_pages">{{pageCountDiff}}</span>
                                        {{/ifCond}}
                                        {{#ifCond pageCountDiff '<' 0}}
                                          <span class="js-cms-table-total_nb_pages_red">{{pageCountDiff}}</span>
                                        {{/ifCond}}
                                      </div>
                                    {{/ifCond}}
                                    {{#ifCond status '!==' "draft"}}
                                      <div>
                                        <span class="cms-dashboard-content-title">Page count</span>
                                        {{#ifCond pageCountUsedForDiff '>' -1}}
                                          {{#ifCond pageCount '>' pageCountUsedForDiff}}
                                            <span class="js-cms-table-total_nb_pages_green">{{pageCount}}</span>
                                          {{/ifCond}}
                                          {{#ifCond pageCount '==' pageCountUsedForDiff}}
                                            <span class="js-cms-table-total_nb_pages">{{pageCount}}</span>
                                          {{/ifCond}}
                                          {{#ifCond pageCount '<' pageCountUsedForDiff}}
                                            <span class="js-cms-table-total_nb_pages_red">{{pageCount}}</span>
                                          {{/ifCond}}
                                        {{/ifCond}}
                                        {{#ifCond pageCountUsedForDiff '==' -1}}
                                          <span class="js-cms-table-total_nb_pages">{{pageCount}}</span>
                                        {{/ifCond}}
                                      </div>
                                    {{/ifCond}}
                                  {{/ifCond}}
                                {{/ifMatch}}
                                <div class="js-cms-rollback-archived">
                                    <span class="cms-dashboard-content-title">Rollback</span>
                                    <span>
                                        {{#if isRollbackable}}
                                            <button type="button"
                                                    class="button-publish-release button button--little button--shikoba button--primary button--publish"
                                                    data-content-id="{{content_id}}" data-locale-id="{{locale}}"
                                                    data-release-id="{{id}}" data-status="{{status}}">
                                        <i class="fa fa-check"></i>
                                        <span>Re-publish now</span>
                                      </button>
                                        {{/if}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    {{/releases}}
                </div>
            </div>
        </div>
    </div>
</section>
