<div class="col-md-12">
    <div class="cms-usersCsv">
        <h2 class="cms-usersCsv-title js-usersCsv-title">User CSV</h2>
        <ul class="cms-usersCsv-list">
            <li>
                <form class="form-inline cms-usersCsv-buttons">
                    <button id="button-download-csv" type="button"
                            class="button button--shikoba button--primary downloadCsv">
                        <i class="fa fa-download"></i>
                        <span>Download current CSV</span>
                    </button>
                    <button id="button-publish-csv" type="button"
                            class="button button--shikoba button--publish publishCsv">
                        <i class="fa fa-check"></i>
                        <span>Publish new CSV</span>
                    </button>
                </form>
            </li>
        </ul>
    </div>
</div>
<div class="col-md-6">
    <div class="csveditor" id="csveditor-view">
        <p class="csveditor-title">Current CSV</p>
        <textarea name="usersCsv-view" data-ref="csv" class="usersCsv-input-view value" data-container="body"
                  data-toggle="popover" data-placement="left" disabled>{{usersCsv.value}}</textarea>
    </div>
</div>
<div class="col-md-6">
    <div class="csveditor" id="csveditor-edit">
        <p class="csveditor-title">New CSV</p>
        <textarea name="usersCsv-edit" data-ref="csv" class="usersCsv-input-edit value" data-container="body"
                  data-toggle="popover" data-placement="left">{{usersCsv.value}}</textarea>
    </div>
</div>
