<div class="col-md-12">
    <div class="cms-editContent">
        <h2 class="cms-editContent-title js-editContent-title">{{id}}</h2>
        <ul class="cms-editContent-list">
            <li>
                <p>Version: {{version}}</p>
            </li>
            <li>
                <p>Description</p>
                <div class="cms-editContent-urls">
                    <input type="text" class="form-control cms-editable" id="content-desc" value="{{desc}}"/>
                </div>
            </li>
            <li>
                <p>Preview url</p>
                <div class="cms-editContent-urls">
                    <input type="text" class="form-control cms-editable" id="content-preview_url"
                           value="{{preview_url}}"/>
                </div>
            </li>
            <li>
                <p>Notification urls</p>
                <p class="cms-editContent-optionButton">
                    <button type="button"
                            class="button button--shikoba button--primary button--little js-addNotification">
                        <i class="fa fa-plus"></i>
                        <span>Add Url</span>
                    </button>
                </p>
                <div id="notifications_urls">
                    {{#notifications_urls}}
                        <div class="js-editContent-urls cms-editContent-urls">
                            <input type="text" class="form-control cms-editable"
                                   id="content-notifications_urls_{{@index}}" placeholder="http://" value="{{.}}"/>
                            <a class="cms-editContent-removeUrl glyphicon glyphicon-remove js-removeNotification"
                               href="#" id="content-remove_notifications_urls_{{@index}}"></a>
                        </div>
                    {{/notifications_urls}}
                </div>
            </li>
            <li id="content-block-delete-row" class="hidden">
                {{#deleteBlocked}}
                    <p>Block delete <input type="checkbox" class="cms-editable" id="content-block-delete"
                                           checked="checked"/><p>
                {{else}}
                    <p>Block delete <input type="checkbox" class="cms-editable" id="content-block-delete"/><p>
                {{/deleteBlocked}}
            </li>
            <li>
                <form class="form-inline cms-editContent-buttons">
                    <button id="button-restore-content" type="button"
                            class="button button--shikoba button--warning restoreContent hidden">
                        <i class="fa fa-undo"></i>
                        <span>Restore previous content</span>
                    </button>
                    <button id="button-delete-content" type="button"
                            class="button button--shikoba button--danger deleteContent hidden">
                        <i class="fa fa-close"></i>
                        <span>Delete content</span>
                    </button>
                    <button id="button-publish-content" type="button"
                            class="button button--shikoba button--publish publishContent">
                        <i class="fa fa-check"></i>
                        <span>Publish content</span>
                    </button>
                </form>
            </li>
        </ul>
    </div>
</div>
<div class="col-md-6">
    <div class="cms-jsoneditor" id="jsoneditor">
        <p class="cms-jsoneditor-title">Default values</p>
    </div>
</div>
<div class="col-md-6">
    <div class="cms-jsoneditor" id="schemaeditor">
        <p class="cms-jsoneditor-title">Schema</p>
    </div>
</div>
