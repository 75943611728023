<div class="modal-dialog">
    <div class="modal-content">
        <div class="cms-modal-header--locales">
            <button type="button" class="cms-button-close js-btn-cancel" data-dismiss="modal" aria-label="Close"><span
                    aria-hidden="true">&times;</span></button>
            <h4 class="cms-modal-title">MULTIPLE Program enable / disable dates</h4>
        </div>
        <div class="modal-body">
            <div class="enable-date-div">
                <span><b>Enable date (appears empty in multiple edit mode)</b></span>
                <div class='input-group date' id='enableMultipleDatetimepicker'>
                    <input id="InputEnableMultipleDatetimepicker" type='text' class="form-control"/>
                    <span class="input-group-addon">
                      <span class="fa fa-calendar"></span>
                    </span>
                </div>
            </div>
            <div class="disable-date-div">
                <span><b>Disable date (appears empty in multiple edit mode)</b></span>
                <div class='input-group date' id='disableMultipleDatetimepicker'>
                    <input id="InputDisableMultipleDatetimepicker" type='text' class="form-control"/>
                    <span class="input-group-addon">
                      <span class="fa fa-calendar"></span>
                    </span>
                </div>
            </div>
            <div class="cms-node-field--error hidden">
            </div>
        </div>
        <div class="cms-modal-footer">
            <p style="color:red">If you continue without setting dates value, all selected schedules will be removed.</p>
            <button type="button"
                    class="button button--shikoba button--primary button--medium button--withoutIcon js-btn-cancel"
                    data-dismiss="modal">
                <span>Back</span>
            </button>
            <button
                    id="button-back-to" type="button"
                    class="button edit-button button--shikoba button--primary button--medium">
                <i class="fa fa-arrow-left"></i>
                <span>Previous Page</span>
            </button>
            <button type="button"
                    class="button button--shikoba button--publish button--medium button--withoutIcon js-btn-OK"
                    data-dismiss="modal">
                <span>Program & save release</span>
            </button>
            <button type="button"
                    class="button button--shikoba button--danger button--medium button--withoutIcon js-btn-remove"
                    data-dismiss="modal">
                <span>Clear programmed date(s)</span>
            </button>
        </div>
    </div><!-- /.modal-content -->
</div><!-- /.modal-dialog -->
