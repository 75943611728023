<div class="col-md-12">
    <div class="cms-migrateContent">
        <div class="cms-migrateContent-header">
            <h2 class="cms-migrateContent-title">Migrate content DCX</h2>
        </div>
        <ul class="cms-migrateContent-list">
            <li>
                <form class="form-inline cms-migrateContent-buttons">
                    <button id="button-get-migrate-content" type="button"
                        class="button button--shikoba button--medium button--publish">
                        <i class="fa fa-check"></i>
                        <span>Get Migrations</span>
                    </button>
                </form>
            </li>
        </ul>
    </div>
</div>
<div class="col-md-12">
    <label for="locales"><span class="glyphicon glyphicon-flag" aria-hidden="true"></span> Locale</label>
    <select id="locales" name="selectLocales"
        class="form-control cms-massOperations-select cms-select-liveSearch selectpicker" data-live-search="true"
        data-actions-box="true">
    </select>
    <label for="tags"><span class="glyphicon glyphicon-tag" aria-hidden="true"></span> Tag</label>
    <select id="tags" name="selectTags"
        class="form-control cms-massOperations-select cms-select-liveSearch selectpicker" data-live-search="true"
        data-actions-box="true">
    </select>
    <label for="type">Type</label>
    <select id="type" name="selectType" class="form-control selectpicker" data-actions-box="true">
        <option value="landing">landing</option>
        <option value="destination">destination</option>
        <option value="destination-tours">destination-tours</option>
    </select>
    <div class="cms-jsoneditor" id="jsoneditor" style="height: 400px;">
        <p class="cms-jsoneditor-title">Migration data</p>
    </div>
</div>