<div class="cms-menuTop cms-editUser">
    <h2 class="cms-editContent-title">Create user</h2>
    <br/>
    <div class="user-form"></div>
    <div class="scopes-grid"></div>
    <div class="cms-user-create-btn-wrapper">
        <button id="button-cancel-update-user" type="button" class="button button--shikoba button--danger button--medium cancel">
            <i class="fa fa-times"></i>
            <span>Cancel</span>
        </button>
        <button id="button-create-user" type="button" class="button button--shikoba button--publish button--medium createUser">
            <i class="fa fa-check"></i>
            <span>Save user</span>
        </button>
    </div>
</div>
