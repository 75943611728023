<div class="modal-dialog cms-modal-dialog --addToken">
    <div class="modal-content">
        <div class="cms-modal-header --addToken">
            <button type="button" class="cms-button-close" data-dismiss="modal" aria-label="Close"><span
                    aria-hidden="true">&times;</span></button>
            <h3 class="cms-modal-title">Add token</h3>
        </div>
        <div class="modal-body">
                <input type="text" class="js-addToken-field form-control cms-editable" placeholder="add token here">
                <p class="cms-addToken-error js-addToken-error"></p>

        </div>
        <div class="cms-modal-footer">
            <button type="button" class="btn cms-button--default js-addToken">Confirm</button>
            <button type="button" class="btn cms-button--default" data-dismiss="modal">Close</button>
        </div>
    </div>
</div>
