<div class="modal-dialog">
    <div class="modal-content">
        <div class="cms-modal-header--locales">
            <button type="button" class="cms-button-close js-btn-cancel" data-dismiss="modal" aria-label="Close"><span
                    aria-hidden="true">&times;</span></button>
            <h4 class="cms-modal-title"><b><u>Markdown Cheatsheet</u></b></h4>
        </div>
        <div class="modal-body">
            <div>
                <b>Currently the enabled Markdown options are:</b>
                <br/>
                <ul>
                    <li><b>Bold text</b></li>
                    <em>syntax: __bold text__ or **bold text**</em>
                    <li><b>Italic text</b> also called Emphasis</li>
                    <em>syntax: _italic text_ or *italic text*</em>
                    <li><b>Links</b></li>
                    <em>syntax: [text](URL) where the URL <b>should be complete</b> (https://www.clubmed.com/l/test), <b>complete links are recommanded for SEO performance</b></em>
                    <li><b>Unordered lists</b> (displayed with bullet points)</li>
                    <em>syntax: every new line is an item and it can start with either -, + or * followed by a space</em>
                </ul>
                <b>If you want to display the special characters used by Markdown as text, you have to escape them by adding a backslash \ in front of them.</b>
                <br/>
                <b><u>
                    EXCEPT IN MARKDOWN LINK'S URLs!
                    <br/>
                    The backslashes will be added to them and they will be incorrect if you do so!
                </u></b>
                <br/>
                <br/>
                <b>Here are the special characters that you need to escape if you want to use them as text:</b>
                <br/>
                <ul>
                    <li>Underscores _</li>
                    <li>Asterisks *</li>
                    <li>Back-ticks `</li>
                    <li>Vertical bars |</li>
                </ul>
                <br/>
                <b>And here are the special characters that you need to escape when they are at the beginning of a new line and followed by a space:</b>
                <br/>
                <ul>
                    <li>Number signs #</li>
                    <li>Plus signs +</li>
                    <li>Minus signs -</li>
                    <li>Greater-than signs ></li>
                </ul>
            </div>
        </div>
        <div class="cms-modal-footer">
            <button type="button" class="btn cms-button--default" data-dismiss="modal">Close</button>
        </div>
    </div><!-- /.modal-content -->
</div><!-- /.modal-dialog -->
