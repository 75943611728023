<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-body">
            <div class="cms-release-tags-header">
                <h3 class="cms-release-tags-title">{{title}}</h3>
            </div>
            <div class="cms-node-json-download">
                <button type="button" class="button button--shikoba button--primary button--medium js-btn-dl-json" data-dismiss="modal"><i class="fa fa-download"></i>Download JSON</button>
                <button type="button" class="button button--shikoba button--primary button--medium js-btn-dl-xlsx" data-dismiss="modal"><i class="fa fa-download"></i>Download XLSX</button>
                <label class="button button--shikoba button--publish button--medium "><i class="fa fa-upload" aria-hidden="true"></i>Upload XLSX<input type="file" name="xlsxfile" id="xlsx" class="hidden">
                </label>
            </div>
            <div class="cms-node-json-warning">
                <h4 class="cms-node-warning-text"><u>WARNING:</u></h4>
                <h4 class="cms-node-warning-text">- do NOT translate keys (they are written in black here or are in the first column in excel)</h4>
                <h4 class="cms-node-warning-text">- do NOT modify the content of the key "@metadata" (or any line which has "@metadata" in its key in excel)</h4>
            </div>
            <ul class="format-choices">
                <li>
                    <button class="format-button active" id="format-button-1">JSON</button>
                </li>
            </ul>
            <div class="cms-jsoneditor jsoneditor-edit-node" id="node-jsoneditor">
            </div>
            <div class="cms-node-field--error hidden">
            </div>
        </div>
        <div class="cms-modal-footer">
            <button type="button" class="btn cms-button--primary js-btn-edit" data-dismiss="modal">Save</button>
            <button type="button" class="btn cms-button--default js-btn-cancel" data-dismiss="modal">Cancel</button>
        </div>
    </div><!-- /.modal-content -->
</div><!-- /.modal-dialog -->
