<div class="cms-duplicateContent">

    <h2 class="cms-duplicateContent-title">Copy content</h2>

    <div class="cms-duplicateContent-row">
        <label for="content"><i class="glyphicon glyphicon-folder-close" aria-hidden="true"></i> Select Content to copy*</label>
        <select id="content" name="selectContent" class="form-control cms-duplicateContent-select">
            <option value="" selected>--</option>
            {{#each contents}}
                <option value="{{ id }}">{{ id }}</option>
            {{/each}}
        </select>
    </div>

    <div class="cms-duplicateContent-row">
        <label for="newContent"><i class="glyphicon glyphicon-folder-close" aria-hidden="true"></i> Type new Content name*</label>
        <input id="newContent" type="text" class="form-control cms-duplicateContent-input" placeholder="Type new Content name here">
    </div>

    <div class="cms-duplicateContent-row">
        <label for="input-include-draft">Include draft releases in copy</label>
        <input type="checkbox" id="input-include-draft" class="cms-editable">
        <p class="text-warning">/!\ including draft releases can multiply the copy time by 3</p>
        <label for="input-include-archived">Include archived releases in copy</label>
        <input type="checkbox" id="input-include-archived" class="cms-editable">
        <p class="text-warning">/!\ including archived releases can multiply the copy time by 10</p>
    </div>

    <div class="cms-duplicateContent-row">
        <label for="newContent">Optional info:</label>
        <form class="form-inline">
            <div id="prefetch">
                <div class="cms-duplicateContent-item">
                    <label for="input-content-desc" class="sr-only">Description</label>
                    <input type="text" class="cms-duplicateContent-field form-control cms-editable" id="input-content-desc"
                           placeholder="Description...">
                </div>

                <div class="cms-duplicateContent-item">
                    <label for="input-content-url" class="sr-only">Preview url</label>
                    <input type="text" class="cms-duplicateContent-field form-control cms-editable" id="input-content-url"
                           placeholder="Preview url...">
                </div>
            </div>

            <div id="notifications" class="cms-duplicateContent-options">
                <div class="cms-duplicateContent-optionButton">
                    <button type="button" class="js-addNotification button button--shikoba button--primary button--medium">
                        <i class="fa fa-plus"></i>
                        <span>Add notification Urls</span>
                    </button>
                </div>
            </div>

            <div id="content-block-delete-row" class="cms-duplicateContent-optionButton hidden">
                <label for="input-block-delete">Block delete</label>
                <input type="checkbox" id="input-block-delete" class="cms-editable">
            </div>
        </form>
    </div>

    <div class="cms-duplicateContent-buttons">
        <button class="btn cms-button--publish js-duplicateContent" type="button">Copy</button>
    </div>

</div>
