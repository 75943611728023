<div class="col-md-12">
    <div class="cms-admin-panel">
        <div class="cms-contents-buttonContainer">
            <button id="button-create-user" class="button button--shikoba button--primary createUser">
                <i class="fa fa-plus"></i>
                <span>Create user</span>
            </button>
            <button id="button-import-user" class="button button--shikoba button--primary displayImportUser">
                <i class="fa fa-plus"></i>
                <span>Import user</span>
            </button>
        </div>
        <div class="cms-subMenu-userActions">
            <form class="form-inline cms-users-buttons">
                <button id="button-init-users" type="button"
                        class="button button--shikoba button--danger editCsv">
                    <i class="fa fa-pencil-square-o"></i>
                    <span>Edit CSV</span>
                </button>
                <button id="button-export-users" type="button"
                        class="button button--shikoba button--primary downloadCsv">
                    <i class="fa fa-download"></i>
                    <span>Download CSV</span>
                </button>
            </form>
        </div>
        <ul id="template-users-container" class="cms-users-list">
            {{#each users}}
                {{#ifNotEq profile "CMS_SUPERADMIN"}}
                    <li data-content="{{ hepta }}" id="{{ hepta }}" class="cms-user-item">

                        <div class="cms-user-cell cms-user-cell--user cms-users-user">
                            <i class="fa fa-user-o" aria-hidden="true"></i>
                            <span class="cms-userCell-text cms-userCell-text--user">{{ hepta }}</span>
                        </div>
                        <div class="cms-user-emailContainer">
                            <div class="cms-user-emailSubContainer">
                                <div class="cms-user-cell cms-user-cell--email cms-users-email">
                                    <i class="fa fa-envelope-o" aria-hidden="true"></i>
                                    <span class="cms-userCell-text cms-userCell-text--email">{{ email }}</span>
                                </div>
                                <div class="cms-user-actions">
                                    <button class="btn cms-button--editUser editUser hidden" data-hepta="{{ hepta }}">
                                        <i class="fa fa-pencil"></i>
                                    </button>
                                    <button class="btn cms-button--delete deleteUser hidden" data-hepta="{{ hepta }}">
                                        <i class="fa fa-trash-o"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </li>
                {{/ifNotEq}}
            {{/each}}
        </ul>
    </div>
</div>
