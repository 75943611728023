<div class="modal-dialog" style="width: 350px; margin-left: auto;margin-right: auto">

    <div class="modal-content">

        <div class="modal-body">
            <h4 style="text-align: center">Choose the wanted <b>{{oppositeRealeaseType}}</b> release</h4>
            <div>
                {{#oppositeRealeases}}
                    <div
                            style="width: 100%"
                            id="{{content_id}}-{{locale}}-{{id}}"
                            data-content-id="{{content_id}}"
                            data-locale-id="{{locale}}"
                            data-release-id="{{id}}"
                            data-status="{{status}}"
                            class="tile clickable-row tile--{{status}}"

                    >
                        <div class="cms-dashboard-item-content">
                            <div>
                                <span class="cms-dashboard-content-title">Status</span>
                                <span style="text-transform: uppercase"><b>{{status}}</b></span>
                            </div>
                            {{#if title}}
                                <div>
                                    <span class="cms-dashboard-content-title">Title</span>
                                    <span><b>{{title}}</b></span>
                                </div>
                            {{/if}}
                            <div>
                                <span class="cms-dashboard-content-title">Release</span>
                                <span>{{#formatId id}}{{/formatId}}</span>
                            </div>
                        </div>
                    </div>
                {{/oppositeRealeases}}
            </div>
            <div style="text-align:right">
                <button type="button" class="btn cms-button--default js-btn-cancel" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div><!-- /.modal-content -->
</div><!-- /.modal-dialog -->