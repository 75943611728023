<div class="modal-dialog">
    <div class="modal-content">
        <div class="cms-modal-header">
            <button type="button" class="cms-button-close" data-dismiss="modal" id="button-close-modal-confirm"
                aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="cms-modal-title">Please confirm</h4>
        </div>
        <div class="modal-body">
            <div class="cms-modal-message" role="alert">
                <span id="modal-confirm-msg"></span><br />
                <strong id="modal-confirm-msg-danger"></strong><br />
                <strong>Are you sure you want to continue?</strong>
            </div>
        </div>
        <!-- /.modal-body -->
        <div class="cms-modal-footer">
            <button type="button" class="button button--shikoba button--medium button--withoutIcon" data-dismiss="modal"
                id="button-OK-modal-confirm">
                <span>OK</span>
            </button>
            <button type="button" class="button button--shikoba button--secondary button--medium button--withoutIcon"
                data-dismiss="modal" id="button-cancel-modal-confirm">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <!-- /.modal-content -->
</div><!-- /.modal-dialog -->