<div class="modal-dialog">
    <div class="modal-content">
        <div class="cms-modal-header--locales">
            <button type="button" class="cms-button-close js-btn-cancel" data-dismiss="modal" aria-label="Close"><span
                    aria-hidden="true">&times;</span></button>
            <h4 class="cms-modal-title">Select a publication Date</h4>
        </div>
        <div class="modal-body">
            <div>
                <div class='input-group date' id='datetimepicker'>
                    <input id="InputDatetimepicker" type='text' class="form-control"/>
                    <span class="input-group-addon">
              <span class="fa fa-close"></span>
              <span class="fa fa-calendar"></span>
            </span>
                </div>

            </div>
        </div>
        <div class="cms-modal-footer">
            <button type="button"
                    class="button button--shikoba button--primary button--medium button--withoutIcon js-btn-cancel"
                    data-dismiss="modal">
                <span>Back</span>
            </button>
            <button
                    id="button-back-to" type="button"
                    class="button edit-button button--shikoba button--primary button--medium">
                <i class="fa fa-arrow-left"></i>
                <span>Previous Page</span>
            </button>
            <button type="button"
                    class="button button--shikoba button--publish button--medium button--withoutIcon js-btn-OK"
                    data-dismiss="modal">
                <span>Program publication</span>
            </button>
            <button type="button"
                    class="button button--shikoba button--danger button--medium button--withoutIcon js-btn-remove"
                    data-dismiss="modal">
                <span>Cancel programmed date</span>
            </button>
        </div>
    </div><!-- /.modal-content -->
</div><!-- /.modal-dialog -->
