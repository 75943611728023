<div class="col-md-12" id="createContent">
    <div class="cms-createContent">
        <h2 class="cms-createContent-title">New content</h2>
        <div>
            <form class="form-inline">
                <div id="prefetch">
                    <div class="cms-createContent-item">
                        <label for="content-name" class="sr-only">Name (required)</label>
                        <input type="text" class="cms-createContent-field form-control cms-editable" id="content-name"
                           placeholder="Content name...">
                    </div>

                    <div class="cms-createContent-item">
                        <label for="content-desc" class="sr-only">Description</label>
                        <input type="text" class="cms-createContent-field form-control cms-editable" id="content-desc"
                           placeholder="Content description...">
                    </div>

                    <div class="cms-createContent-item">
                        <label for="content-preview_url" class="sr-only">Preview url</label>
                        <input type="text" class="cms-createContent-field form-control cms-editable" id="content-preview_url"
                           placeholder="Preview url...">
                    </div>
                </div>

                <div id="notifications" class="cms-createContent-options">
                    <div class="cms-createContent-optionButton">
                        <button type="button" class="js-addNotification button button--shikoba button--primary button--medium">
                          <i class="fa fa-plus"></i>
                          <span>Add notification Urls</span>
                        </button>
                    </div>
                </div>

                <div id="content-block-delete-row" class="cms-createContent-optionButton hidden">
                    <label for="content-block-delete">Block delete</label>
                    <input type="checkbox" id="content-block-delete" class="cms-editable">
                </div>

                <button id="button-create-content" type="button" class="button button--shikoba button--primary button--medium cms-createContent-create">
                  <i class="fa fa-plus"></i>
                  <span>Create</span>
                </button>
            </form>
        </div>
    </div>
</div>
