{{#if account.access_token}}
<div>
    <i id="icon-trident" class="Icon Icon--trident"></i>
    <nav class="cms-navbar">
        <button type="button" class="navbar-toggle collapsed js-button-toggle" data-toggle="collapse"
            data-target="#navbar" aria-expanded="false" aria-controls="navbar">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
        </button>
        <div class="cms-navbar-menu">
            <a class="cms-navbar-link cms-navbar-link--logo" id="button-home">
                <i class="Icon Icon--clubmed"></i>
            </a>
            <div id="navbar" class="collapse navbar-collapse">
                <ul class="cms-navbar-list navbar-nav">
                    <li id="button-display-contents-section" class="hidden cms-navbar-listItem">
                        <a class="cms-navbar-link js-header-toContent js-navbar-link"><i class="fa fa-folder-o"
                                aria-hidden="true"></i> Contents</a>
                    </li>
                    <li id="button-display-contents-duplicate" class="hidden cms-navbar-listItem">
                        <a class="cms-navbar-link js-header-toDuplicateContent js-navbar-link"><i class="fa fa-files-o"
                                aria-hidden="true"></i> Copy Content</a>
                    </li>
                    <li id="button-display-contents-import" class="hidden cms-navbar-listItem">
                        <a class="cms-navbar-link js-header-toImportContent js-navbar-link"><i
                                class="fa fa-cloud-download" aria-hidden="true"></i> Import Content</a>
                    </li>
                    <li id="button-development-api-doc" class="hidden cms-navbar-listItem">
                        <a class="cms-navbar-link js-navbar-link"><i class="fa fa-book" aria-hidden="true"></i>
                            Swagger</a>
                    </li>

                    <li id="admin-dropdown" class="dropdown hidden cms-navbar-listItem">
                        <a class="cms-navbar-link" data-toggle="dropdown" role="button" aria-haspopup="true"
                            aria-expanded="false"><i class="fa fa-cube" aria-hidden="true"></i> Admin <span
                                class="caret"></span></a>
                        <ul class="dropdown-menu">
                            <li id="item-display-list-migrate-content"><a
                                    class="cms-dropdown-menu-link js-navbar-link">Migration V1</a></li>
                            <li id="item-display-list-migrate-content-v2"><a
                                    class="cms-dropdown-menu-link js-navbar-link">Migration V2</a></li>
                            <li id="item-display-list-migrate-content-dcx"><a
                                    class="cms-dropdown-menu-link js-navbar-link">Migration Dcx</a></li>
                            <li id="item-display-list-users" class="hidden"><a
                                    class="cms-dropdown-menu-link js-navbar-link">Users</a></li>
                        </ul>
                    </li>

                    <li id="po-dropdown" class="dropdown hidden cms-navbar-listItem">
                        <a class="cms-navbar-link" data-toggle="dropdown" role="button" aria-haspopup="true"
                            aria-expanded="false"><i class="fa fa-key" aria-hidden="true"></i> Feature Flip <span
                                class="caret"></span></a>
                        <ul class="dropdown-menu">
                            <li id="item-display-feature-flip-content" class="hidden"><a
                                    class="cms-dropdown-menu-link js-navbar-link ">Keys</a></li>
                            <li id="item-display-edit-feature-flip-content" class="hidden"><a
                                    class="cms-dropdown-menu-link js-navbar-link">Edit</a></li>
                        </ul>
                    </li>

                    <li class="cms-navbar-listItem cms-navbar-user">
                        <span id="button-empty-welcome" class="cms-user-name">Welcome</span>
                        <span id="button-welcome" class="cms-user-name hidden">
                            <span id="welcome-icon" class="icon"><i class="fa fa-user" aria-hidden="true"></i></span>
                            <span id="welcome-name" class="name">{{account.name}}</span>
                        </span>
                        <span id="button-logout" class="cms-navbar-logout">
                            <a class="cms-navbar-link--logout"><i class="fa fa-sign-out" aria-hidden="true"></i></a>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</div>
{{/if}}